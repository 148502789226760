.delete-account-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  align-items: center;
  padding: 0 50px;
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 98%;
  padding-left: 20px;
  padding-top: 10px;
  .header-container-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
  }
}
.delete-account-success {
  font-size: 30px;
}
.delete-account-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 70px;
}
.delete-account-col {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.post-code-label {
  margin-bottom: 10px;
}
.post-code-icon {
  margin-right: 5px;
  margin-top: 15px;
  font-size: 16px;
  line-height: 22px;
}
.delete-account-link {
  margin-left: 5px;
  color: rgb(14, 111, 186);
  text-decoration: underline;
  cursor: pointer;
}
.ml-20 {
  margin-left: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.delete-account-item {
  margin-bottom: 10px;
}
.facility-name-label {
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}
.delete-account-row-link {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-between;
  margin-top: 20px;
}
.delete-account-row-link-center {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: center;
  margin-top: 20px;
}
.delete-account-otp-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.delete-account-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.delete-account-otp-input {
  margin-right: 30px;
}
.delete-account-otp-space {
  margin-right: 30px;
}
.otp-error-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.otp-error-text1 {
  font-weight: 400;
  font-style: normal;
  color: rgb(255, 49, 49);
  text-decoration: none;
}
.otp-error-text2 {
  font-weight: 400;
  font-style: normal;
  color: rgb(255, 49, 49);
  text-decoration: none;
}
.delete-account-otp-success {
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 24px;
  margin-top: 100px;
}
.delete-account-status-content {
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 26px;
  margin-top: 30px;
}
.delete-account-row-link-center {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: center;
  margin-top: 20px;
}
.delete-account-link {
  margin-left: 5px;
  color: rgb(14, 111, 186);
  text-decoration: underline;
  cursor: pointer;
}
.error-text {
  margin-bottom: 10px;
  // margin-left: 10px;
  line-height: 32px;
}

.delete-account-otp-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.delete-account-otp-input {
  margin-right: 30px;
}
.delete-account-otp-space {
  margin-right: 30px;
}
.reset-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 30px;
}
.reset-header-container-item {
  height: 66px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.reset-header-screen-name {
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 28px;
}
