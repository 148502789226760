.App {
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url("../public/icons/background.png");
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body {
  overflow: hidden;
}
.ant-input-group-addon {
  border-bottom: 1px solid #0e6fba !important;
  border-left: 1px solid #0e6fba !important;
  border-top: 1px solid #0e6fba !important;
  cursor: pointer;
}
.ant-input {
  border: 1px solid #0e6fba;
}
.error {
  color: red;
  font-size: 12px;
}
.modal-confirm-text {
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 22px;
  margin-bottom: 20px;
}
.modal-confirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.mr-20 {
  margin-right: 20px;
}
.app-container {
  /* position: fixed;
  top: "105px";
  left: "0px"; */
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 105px);
}
