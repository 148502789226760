.csv-optional-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20px 20px 20px;
}

.react-datepicker-wrapper {
    height: 32px !important;

    .react-datepicker__input-container input {
        height: 20px;
        width: 200px;
    }
}

.csv-exported-link {
    cursor: pointer;
    font-weight: 400;
    font-style: normal;
    color: rgb(14, 111, 186);
    text-decoration: underline;
}