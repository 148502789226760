.detail-container {
  margin-top: 20px;
  padding: 0 50px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .detail-header {
    height: 10;
  }
}

.patients-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
}

.note-text {
  font-size: 9px;
  font-weight: 400;
  font-style: normal;
  color: rgb(84, 84, 84);
  text-decoration: none;
  margin-bottom: 10px;
}



.empty-data {
  text-align: center;
  font-size: 24px;
  margin-bottom: 140px;
}