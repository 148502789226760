.datepicker-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.datepicker-title {
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  line-height: 32px;
}

.datepicker-container .ant-datepicker {
  width: 100%;
  height: 50px;
}

.ant-picker {
  border: 1px solid #0e6fba;
  border-radius: 6px;
}

.datepicker-error-message {
  color: red;
  font-size: 12px;
}

.react-datepicker-wrapper {
  height: 32px !important;
  margin-inline: 10px;

  .react-datepicker__input-container input {
    height: 20px;
    border-radius: 5px;
    border-color: #0e6fba;
  }
}