.deg-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.deg-submit-button {
  margin-top: 20px;
}

.value-text {
  font-weight: 500;
}

.block-padding {
  margin-block: 20px;
}

.link-payment {
  margin-left: 5px;
  margin-top: 15px;
  color: rgb(14, 111, 186);
  text-decoration: underline;
  cursor: pointer;
  line-height: 32px;
}

.cost-box {
  color: whitesmoke;
  background-color: #b4b2b2;
  padding-block: 4.0px;
  padding-inline: 6.0px;
  border-radius: 3px;
}

.select-options {
  &.pendding {
    .ant-select-selection-item {
      color: #ff5757;
    }
  }

  &.paided {
    .ant-select-selection-item {
      color: #2abf63;
    }
  }

  &.confirmed {
    .ant-select-selection-item {
      color: #f7b42f;
    }
  }
}