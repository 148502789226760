.form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.input-title {
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  line-height: 32px;
  text-align: left;
  height: 32px;
}
.input-subtitle {
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  text-align: left;
}
.input-error-message {
  color: red;
  font-size: 12px;
  text-align: left;
}
.ant-input {
  border: 1px solid #0e6fba;
}
.input-and-description {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
}
.des-text {
  width: 100px;
}
.error-text {
  border: 1px solid red !important;
}
.pass-eye {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.pr-30 {
  padding-right: 30px;
}
input::-ms-reveal {
  display: none;
}
