.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 98%;
  padding-left: 20px;
  padding-top: 10px;
  .header-container-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
  }
}
.header-container-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  padding: 20px 10px 0px 10px;
}
.header-container-item {
  height: 66px;
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
}
.header-container .header-screen-name {
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 28px;
}
.menu {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.header-item {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  min-width: 200px;
}
.header-item-filter {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  min-width: 300px;
}
.header-item-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
}
.header-item-row {
  display: flex;
  flex-direction: row;
  line-height: 32px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-item-row-text {
  display: flex;
  flex-direction: row;
  line-height: 15px;
  margin-bottom: 5px;
}
.header-item-row-label {
  width: 100px;
}
.header-item-row-content {
  width: calc(100% - 100px);
}
.logo-icon {
  cursor: pointer;
  margin-left: 20px;
  width: 200px;
  height: 50px;
}
