.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.input-title {
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  line-height: 32px;
  text-align: left;
}
.input-subtitle {
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  text-align: left;
}
.input-error-message {
  color: red;
  font-size: 12px;
  text-align: left;
}
.ant-input {
  border: 1px solid #0e6fba;
}
