.form-patient-detail-container {
  display: flex;
  flex-direction: column;
}
.form-patient-detail-rowid {
  width: 100%;
}
.form-patient-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.form-patient-detail-col {
  width: 19%;
  display: flex;
  flex-direction: row;
  // align-items: flex-end;
  justify-content: space-between;
  margin-right: 10px;
}
.form-patient-detail-col-merge {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.form-patient-detail-loading-sensor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-patient-detail-loading-icon {
  width: 66px;
  height: 66px;
}
.form-patient-detail-edit-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.form-patient-detail-loading-message {
  margin: 20px 0px;
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 26px;
}
.form-patient-detail-search-result {
}
.form-patient-detail-search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-patient-detail-search-result-message {
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 26px;
  margin-bottom: 20px;
}
.form-patient-detail-search-result-list {
  margin-bottom: 30px;
}
.form-patient-detail-search-result-item {
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  cursor: pointer;
}
.form-patient-detail-search-result-item-text {
  font-weight: 500;
  font-style: normal;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 30px;
}
.form-patient-detail-form-multi {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-patient-detail-list-diseases {
  font-size: 14px;
  margin-left: 5px;
}
.check-icon {
  height: 32px;
  margin-left: 10px;
  cursor: pointer;
}
.form-patient-footer-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mr-10 {
  margin-right: 10px;
}
.modal-chart-patient-layout {
}
.modal-chart-patient-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.modal-chart-patient-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
.mt-20 {
  margin-top: 20px;
}
.cancel-modal-button {
  height: 32px;
  width: 100px;
  background-color: #a6a6a6;
  text-align: center;
  color: white;
  line-height: 32px;
  border-radius: 5px;
  cursor: pointer;
}
.delete-sensor-modal-button {
  height: 32px;
  width: 180px;
  background-color: rgb(255, 87, 87);
  text-align: center;
  color: white;
  line-height: 32px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}
.form-patient-detail-row-seach {
  display: flex;
  flex-direction: row;
}
