.ant-btn .button-text {
  font-weight: 500;
  font-style: normal;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
}
.ant-btn:disabled {
  background: rgb(166, 166, 166) !important;
}
.ant-btn:disabled .button-text {
  font-weight: 500;
  font-style: normal;
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
  font-size: 16px;
}
.button-common {
  height: 32px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  border: none;
  background-color: #1677ff;
  font-size: 14px;
  padding: 4px 15px;
}
.button-common:disabled {
  cursor: not-allowed;
  background-color: #a6a6a6 !important;
}
