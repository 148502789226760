body {
  margin: 0;
  font-family: MonospaceRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "MonospaceRegular";
  src: local("MonospaceRegular"),
    url(./assets/fonts/MonospaceRegular.ttf) format("woff");
}
.ant-btn {
  border-radius: 12px;
}
